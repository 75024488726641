import React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import "./home.css";

function Home() {
  /**
   * State creation
   *  Etablissement
   *  Beneficiaire
   *  DailyEtablissement
   *  DailyBeneficiaire
   *
   */
  const [etablissement, setEtablissement] = useState([]);
  const [beneficiaire, setBeneficiaire] = useState([]);
  const [dailyEtablissement, setDailyEtablissement] = useState([]);
  const [dailyBeneficiaire, setDailyBeneficiaire] = useState([]);
  /**
   * Get All Etablissement
   */
  const getEtablissement = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/backOffice/getEtablissement",
      options
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          const monProfil = responseObject;
          console.log("profil", monProfil);
          setEtablissement(monProfil.length);
        },

        (error) => {
          console.log(error);
        }
      );
  };

  /**
   * Get All Bénéficiaire
   */
  const getBeneficiaire = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/backOffice/getBeneficiaire",
      options
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          const monProfil = responseObject;
          console.log("beneficaire", monProfil);
          setBeneficiaire(monProfil.length);
        },

        (error) => {
          console.log(error);
        }
      );
  };

  /**
   * Get Daily Bénéficiaire
   */
  const getDailyBeneficiaire = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/backOffice/getDailyBeneficiaire",
      options
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          const monProfil = responseObject;
          console.log("beneficaire", monProfil);
          setDailyBeneficiaire(monProfil.length);
        },

        (error) => {
          console.log(error);
        }
      );
  };

  /**
   * Get Daily Etablissement
   */
  const getDailyEtablissement = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/backOffice/getEtablissement",
      options
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          const monProfil = responseObject;
          console.log("profil", monProfil);
          setDailyEtablissement(monProfil.length);
        },

        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    // Call of all the get
    getEtablissement();
    getBeneficiaire();
    getDailyBeneficiaire();
    getDailyEtablissement();
  }, []);

  return (
    <div>
      <Row>
        <Col>
          <h2>Vision d'ensemble</h2>
        </Col>
      </Row>
      <div className="rowBox">
        <div className="element">
          <h4> Nombre d'établissements</h4>
          <h4>{etablissement}</h4>
        </div>
        <div className="element">
          <h4> Nombre de bénéficiaires</h4>
          <h4>{beneficiaire}</h4>
        </div>
        <div className="element">
          <h4> Nombre d'inscriptions aujourd'hui</h4>

          <h4>{dailyBeneficiaire + dailyEtablissement}</h4>
        </div>
      </div>
    </div>
  );
}

export default Home;
