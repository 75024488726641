import React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import Modal from "react-awesome-modal";
import "./beneficiaire.css";

import Moment from "react-moment";

function Beneficiaire() {
  const [beneficiaire, setBeneficiaire] = useState([]);
  const [index, setIndex] = useState();
  const [sum, setSum] = useState(1);
  let newData = [];
  const [fake, setFake] = useState([1, 2, 3, 4]);
  const [modalTips, setModalTips] = useState(false);

  const handleCloseModal = () => setModalTips(false);
  const getBeneficiaire = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/backOffice/getBeneficiaire",
      options
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          const monProfil = responseObject;

          setBeneficiaire(monProfil);
        },

        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    getBeneficiaire();
  }, []);

  const renderBeneficiaire = () => {
    return beneficiaire.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <Moment format="DD/MM/YYYY">{item?.inscriptionDate}</Moment>
          </td>
          <td>
            {item.firstname} {item.lastname}
          </td>
          <td>{item.email}</td>
          <td>{!item.kycStatut ? "Pas de document" : item.kycStatut}</td>
          <td>
            {item.history.length}

            {!item.history.length ? null : (
              <button
                onClick={() => {
                  setIndex(index);
                  setModalTips(true);
                }}>
                Voir +
              </button>
            )}
          </td>
          {item.restaurant.map((element) => {
            return (
              <td>
                {!item.restaurant.length
                  ? "Pas  de restaurant"
                  : element.restaurantName}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div>
      <Modal
        visible={modalTips}
        width="600"
        height="500"
        effect="fadeInUp"
        onClickAway={handleCloseModal}>
        <div className="affiPop">
          <h1>Mes historiques de pourboire</h1>
          {beneficiaire[index]?.history.map((item) => {
            return (
              newData.push(item.amount),
              console.log("newData", newData),
              console.log(
                "newdata2",
                newData.reduce((partialSum, a) => partialSum + a, 0)
              )
            );
          })}

          <h5>
            Total : {newData.reduce((partialSum, a) => partialSum + a, 0) / 100}
            €
          </h5>
          {beneficiaire[index]?.history.map((element, key) => {
            return (
              <p>
                Donnée le :{" "}
                <Moment format="DD/MM/YYYY à HH:MM">{element.date}</Moment> :{" "}
                <br />
                Montant : {element.amount / 100} €
              </p>
            );
          })}
        </div>
      </Modal>

      <Row></Row>
      <Col>
        <h2>Vision d'ensemble bénéficiaire</h2>
      </Col>
      <div className="rowBox">
        <Row className="element">
          <Col className="tableCol" md={12}>
            <Table striped bordered hover>
              <th>Date d'inscription</th>
              <th>Nom - Prénom</th>
              <th>mail</th>
              <th>KYC Status</th>
              <th>Historique Pourboire</th>
              <th>Etablissement(s)</th>

              <tbody>{renderBeneficiaire()}</tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Beneficiaire;
