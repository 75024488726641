import React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Table, Button, Alert } from "react-bootstrap";
import Modal from "react-awesome-modal";
import "./etablissement.css";
import QRCodeTicket from "../assets/QRCodeTicket";
import QRCodecarte from "../assets/QRCodeCarte";
import Moment from "react-moment";

function Etablissement() {
  const [etablissement, setEtablissement] = useState([]);
  const [beneficiaire, setBeneficiaire] = useState([]);
  const [history, setHistory] = useState([]);
  const [indexEmploye, setIndexEmploye] = useState(0);
  const [show, setShow] = useState(false);
  const [show4, setShow4] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalCarte, setModalCarte] = useState(false);
  const [wallet, setWallet] = useState({});
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow4(false);
  const handleCloseCarte = () => setModalCarte(false);
  const handleCloseModal = () => setModal(false);

  // Récupération des établissements
  const getEtablissement = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/backOffice/getEtablissement",
      options
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          const monProfil = responseObject;
          console.log("profil", monProfil);
          setEtablissement(monProfil);
        },

        (error) => {
          console.log(error);
        }
      );
  };

  const getBeneficiaire = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/backOffice/getBeneficiaire",
      options
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          const monProfil = responseObject;

          setBeneficiaire(monProfil);

          console.log("array", beneficiaire);
        },

        (error) => {
          console.log(error);
        }
      );
  };

  const getWallet = (email) => {
    //e.preventDefault();
    const data = {
      email: email,
    };

    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + localStorage.getItem("token"),
    });

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers,
    };

    fetch("http://localhost:8080/backOffice/getWallet", options)
      .then((response) => {
        return response.json();
      })

      .then((responseData) => {
        console.log("data", responseData.Balance.Amount);
        setWallet(JSON.stringify(responseData.Balance.Amount));
      });
  };

  useEffect(() => {
    getEtablissement();
    getBeneficiaire();
    getWallet();
  }, []);
  useEffect(() => {
    console.log("proff", beneficiaire);
  }, [indexEmploye, beneficiaire]);

  const renderEtablissement = () => {
    return etablissement.map((item, index) => {
      console.log("item", item.referent?.email === "");
      return (
        <tr key={index}>
          <td>
            {" "}
            <Moment format="DD/MM/YYYY">{item?.inscriptionDate}</Moment>
          </td>
          <td>{item.restaurantName}</td>
          <td>{item.email}</td>
          <td>
            {item.tabServeur.length}
            <button
              onClick={() => {
                setIndexEmploye(index);
                setShow(true);
              }}>
              Voir +
            </button>{" "}
          </td>
          {item.referent === undefined || item.referent?.email === "" ? (
            <td>Pas de référent</td>
          ) : (
            <td>
              {item.referent?.name}
              <br />
              {item.referent?.email}
            </td>
          )}

          <td>
            <button
              onClick={() => {
                getWallet(item.email);
                setIndexEmploye(index);
                setShow4(true);
              }}>
              voir
            </button>
          </td>
          <td>
            {item.menu.dailyMenu.picture == "" ? null : (
              <button
                onClick={() => {
                  setIndexEmploye(index);
                  setModalCarte(true);
                }}>
                CARTE
              </button>
            )}
            <button
              onClick={() => {
                setIndexEmploye(index);
                setModal(true);
              }}>
              TICKET
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="etabl">
      <Modal
        visible={modal}
        width="300"
        height="600"
        effect="fadeInUp"
        onClickAway={handleCloseModal}>
        <div className="affiPop">
          <h1>QR CODE TICKET</h1>
          <p> {etablissement[indexEmploye]?.restaurantName}</p>

          {modal ? (
            <QRCodeTicket
              etablissement={etablissement[indexEmploye]?.restaurantName}
            />
          ) : null}
        </div>
      </Modal>
      <Modal
        visible={modalCarte}
        width="300"
        height="600"
        effect="fadeInUp"
        onClickAway={handleCloseCarte}>
        <div className="affiPop">
          <h1>QR CODE CARTE</h1>
          <p> {etablissement[indexEmploye]?.restaurantName}</p>

          {modalCarte ? (
            <QRCodecarte
              etablissement={etablissement[indexEmploye]?.restaurantName}
            />
          ) : null}
        </div>
      </Modal>
      <Modal
        visible={show}
        width="600"
        height="500"
        effect="fadeInUp"
        onClickAway={handleClose}>
        <div className="affiPop">
          <h1>Liste des employés</h1>
          {etablissement[indexEmploye]?.tabServeur.map((element) => {
            return (
              <p>
                {element.serveurName}
                {""} {element.serveurLastName} {""}/ {element.serveurMail}
              </p>
            );
          })}
        </div>
      </Modal>
      <Modal
        visible={show4}
        width="600"
        height="300"
        effect="fadeInUp"
        onClickAway={handleClose2}>
        <div className="affiPop">
          <h1>Nombre de tips</h1>
          {etablissement[indexEmploye]?.communTip.length}
          <h1>Total</h1>
          {etablissement[indexEmploye]?.communTip.length === 0
            ? "0 €"
            : etablissement[indexEmploye]?.communTip
                .map((datum) => datum.amount)
                .reduce((a, b) => a + b) /
                100 +
              " €"}
        </div>
      </Modal>

      <Row></Row>
      <div className="rowBox">
        <Row className="element">
          <Col>
            <h2>Vision d'ensemble établissement</h2>
          </Col>
          <Col className="tableCol" md={12}>
            <Table striped bordered hover>
              <th>Date d'inscription</th>
              <th>Etablissement</th>
              <th>mail</th>
              <th>Nbre d'employés</th>
              <th>Référent désigné</th>
              <th>
                Pourboires
                <br />
                Nbre / Total €
              </th>
              <th>QR CODE</th>
              <tbody>{renderEtablissement()}</tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Etablissement;
