import { BrowserRouter, Switch, Route, Routes, Link } from "react-router-dom";
import Menu from "./assets/menu";
/* import composants */

import Home from "./components/home";
import Etablissement from "./components/etablissement";
import Beneficiaire from "./components/beneficiaire";

function App() {
  return (
    <BrowserRouter>
      <Menu />
      <div>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Etablissement" element={<Etablissement />} />
          <Route path="/Beneficiaire" element={<Beneficiaire />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;
