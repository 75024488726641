import React from "react";
import { Row, Col, Table, Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./menu.css";

function Menu() {
  return (
    <div>
      <Container fluid>
        <Row className="partie1">
          <Dropdown className="nav justify-content-right">
            <Dropdown.Toggle
              alignright="true"
              variant="success"
              id="dropdown-basic"
            >
              <img src="/images/user.png" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/">Accueil</Dropdown.Item>
              <Dropdown.Item href="/Etablissement">Etablissement</Dropdown.Item>
              <Dropdown.Item href="/Beneficiaire">Bénéficiaire</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Col className="logoPartie1" md={12}>
            <a href="https://tipourboire.com">
              <img src="/images/logoJaune.png" />
            </a>
            <h1 className="title">Back-office</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Menu;
